export const isWiremockEnabled = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('wiremock') === 'true';
};

export const getMockUrls = () => {
    if (isWiremockEnabled()) {
        return {
            pertinenceUrl: 'http://localhost:9481/v1/reco/339/recos/ca22f203-f711-48ca-bcc9-887b3fb92ac8',
            bestSalesUrl: 'http://localhost:9481/v1/reco/339/recos/f13e576b-b977-4280-bec8-20d19ae97227'
        };
    }
    return null;
};
export const getPertinenceUrl = () => {
    const urls = getMockUrls();
    return urls ? urls.pertinenceUrl : null;
};

export const getBestSalesUrl = () => {
    const urls = getMockUrls();
    return urls ? urls.bestSalesUrl : null;
};

function updateImage(selectedImage, updatedImage) {
    const image = updatedImage;
    image.src = selectedImage;
}

function updateTitle(selectedTitle, updatedTitle) {
    const titleElement = updatedTitle;
    titleElement.textContent = selectedTitle;
}

function updatePrice(selectedPrice, updatedPrice, dataStrippedPrice) {
    const updatePriceContainer = updatedPrice.closest('.custom-pack__price');
    const priceElement = updatedPrice;
    priceElement.innerHTML = selectedPrice.innerHTML;
    if (dataStrippedPrice > 0) {
        updatePriceContainer.classList.add('custom-pack__price--is-striped');
    } else {
        updatePriceContainer.classList.remove('custom-pack__price--is-striped');
    }
}

function updateCodic(selectedCodic, checkbox) {
    const id = `pack-item-${selectedCodic}`;
    const updatedCheckbox = checkbox;
    updatedCheckbox.value = selectedCodic;
    updatedCheckbox.id = id;
    updatedCheckbox.nextElementSibling.setAttribute('for', id);
}

function updateDataPrice(price, strippedPrice, priceObj) {
    priceObj.setAttribute('data-price', price);
    priceObj.setAttribute('data-stripped-price', strippedPrice);
    priceObj.setAttribute('data-selected-price', price);
    priceObj.setAttribute('data-selected-stripped-price', strippedPrice);
}

function updateUrl(selectedUrl, updatedUrl) {
    const urlElement = updatedUrl;
    urlElement.href = selectedUrl;
}

function updatePreview(element, selectedItem, dataStrippedPrice) {
    const dropListContainer = element.closest('.recommendation-select__container');
    const updatedImage = selectedItem.querySelector('.custom-pack__picture');
    const updatedTitle = selectedItem.querySelector('.custom-pack__brand-reference');
    const selectedTitle = element.dataset.title;
    const selectedImage = element.dataset.image;
    const selectedPrice = element.querySelector('.product-offer');
    const updatedPrice = selectedItem.querySelector('.product-offer');
    const selectedUrl = element.dataset.url;
    const updatedUrl = selectedItem.querySelector('.custom-pack__link');
    updateImage(selectedImage, updatedImage);
    updateTitle(selectedTitle, updatedTitle);
    updatePrice(selectedPrice, updatedPrice, dataStrippedPrice);
    updateUrl(selectedUrl, updatedUrl);
    dropListContainer.classList.remove('recommendation-select__container--is-selected');
}

function closeDropdownList() {
    document.body.classList.remove('js-custom-pack-overlay-is-open');
    document.querySelectorAll('.recommendation-select__container').forEach((container) => {
        container.classList.remove('recommendation-select__container--is-selected');
    });
}

function updateSelectedProduct(element) {
    const currentType = element.dataset.productType;
    const selectedItem = document.querySelector(
        `.custom-pack__item[data-product-type="${currentType}"] .custom-pack__item-container`
    );
    const checkbox = selectedItem.closest('.custom-pack__item').querySelector('.js-checkbox-input');
    const priceObj = selectedItem.querySelector('.custom-pack__main-product');
    const selectedCodic = element.dataset.codic;
    const dataPrice = element.dataset.price;
    const dataStrippedPrice = element.dataset.strippedPrice;
    updateCodic(selectedCodic, checkbox);
    updateDataPrice(dataPrice, dataStrippedPrice, priceObj);
    updatePreview(element, selectedItem, dataStrippedPrice);
    closeDropdownList();
}

function selectedItemInList(event) {
    const element = event.currentTarget;
    updateSelectedProduct(element);
    if (document.querySelector('.custom-pack__mobile-bottom')) {
        GLOBAL_FUN.closeModal();
    }
    const customEvent = new CustomEvent('custom_pack.item_selected', { detail: element });
    document.dispatchEvent(customEvent);
}

export default function initInteraction() {
    document.body.classList.add('custom-pack-interaction-is-loaded');
    document.querySelectorAll('.recommendation-select__item-button').forEach((button) => {
        button.addEventListener('click', selectedItemInList);
    });
}

function ajaxTracking(codicsArray) {
    const basketTracking = {
        codics: JSON.stringify(codicsArray),
        page: 'MAIN_PAGE',
        event: 'scAdd,event202',
        v48: 'reco_pack-configurable'
    };

    $.ajax({
        url: '/tunnel_achat/api/tracking/click',
        data: basketTracking,
        datatype: 'json',
        type: 'GET',
        complete() {
            window.location = '/tunnel_achat/panier';
        }
    });
}

function addToBasketTracking(codicsArray) {
    const eventId = `ajout panier pack-configurable:${codicsArray.length - 1}`;
    if (window.DARTY_CONF.OMNITURE) {
        window.DARTY_CONF.OMNITURE.triggerTracking(eventId, 'clic');
    }
}

export default function initAddBasket() {
    const $customPackAddBtn = $('#js-custom-pack__btn-buy');
    let codicObject = {};
    const ebProductsCodics = [];
    const codicsArray = [];

    $('.js-checkbox-input:checked').each(function addToSelectedProducts() {
        ebProductsCodics.push({ codic: $(this).val() });
        codicsArray.push($(this).val());
    });

    if ($customPackAddBtn.attr('data-offer-id') && $customPackAddBtn.attr('data-offer-id').length) {
        codicObject = {
            codic: $customPackAddBtn.attr('data-codic').toString(),
            offerId: $customPackAddBtn.attr('data-offer-id').toString()
        };
    } else {
        codicObject = {
            codic: $customPackAddBtn.attr('data-codic').toString()
        };
    }
    ebProductsCodics.unshift(codicObject);
    codicsArray.unshift($customPackAddBtn.attr('data-codic').toString());
    $.ajax({
        url: '/tunnel_achat/api/cart/add',
        data: `cartAdditions=${JSON.stringify(ebProductsCodics)}`,
        type: 'POST',
        complete() {
            addToBasketTracking(codicsArray);
            ajaxTracking(codicsArray);
        }
    });
}

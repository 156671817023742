import { initRecommendations } from '../../recommendations/recommendations.js';
import initRecommendationsFilter from '../../recommendations/recommendations-filter.js';

const loadFunction = () => {
    initRecommendations();
    initRecommendationsFilter();
};

(() => {
    window.addEventListener('DOMContentLoaded', loadFunction);
})();

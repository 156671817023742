import initPrices from './custom_pack_prices.js';
import initInteraction from './custom_pack_interaction.js';
import initAddBasket from './custom_pack_add_basket.js';

function applyCustomPackInlineStyles(numberProductRecommendations, isMobile) {
    document.querySelector('.custom-pack__list').classList.add('custom-pack__list--is-inline');
    document.querySelector('.custom-pack__container').classList.add('custom-pack__container--is-inline');

    if (!isMobile) {
        document.querySelector('.custom-pack__container').classList.add('d-flex');
        document.querySelector('.recommendation-summary').classList.add('recommendation-summary--is-inline');
    }

    if (numberProductRecommendations < 2) {
        document.querySelector('.custom-pack__title').textContent = 'Choisissez votre accessoire';
    }
}

export default function initCustomPack() {
    const customPackContainer = document.querySelector('.js-custom-pack');
    const customPackReco = document.querySelector('.js-custom-pack');
    const { body } = document;
    const isMobile = customPackContainer.dataset.versionMobile === 'true';

    if (customPackReco && !body.classList.contains('custom-pack-is-loaded')) {
        body.classList.add('custom-pack-is-loaded');
        customPackContainer.classList.add('custom-pack--is-visible');
        const numberProductRecommendations = customPackReco.querySelectorAll('.custom-pack__item').length;

        if (numberProductRecommendations < 3) {
            applyCustomPackInlineStyles(numberProductRecommendations, isMobile);
        }

        initPrices();
        initInteraction();
        document.querySelector('#js-custom-pack__btn-buy').addEventListener('click', initAddBasket);
    }
}

import { fetchRecommendations } from './recommendations.js';

const closeDropdown = (filterContainer, dropdownContainer) => {
    window.addEventListener('click', (e) => {
        if (!filterContainer.contains(e.target)) {
            dropdownContainer.classList.remove('is-active');
        }
    });
};

const openDropdown = (dropdownContainer) => {
    dropdownContainer.classList.add('is-active');
};

const selectFilter = (recoContainer, dropdownContainer) => {
    let filterButtons = Array.from(dropdownContainer.querySelectorAll('.xsell-carousel__filter-check'));
    const blockName = recoContainer.getAttribute('data-recommendation-block');
    const recommendationsContainer = recoContainer.querySelector(`.recommendation-container__${blockName}`);

    filterButtons.forEach((button, index) => {
        const newButton = button.cloneNode(true);
        button.replaceWith(newButton);

        filterButtons = [...filterButtons.slice(0, index), newButton, ...filterButtons.slice(index + 1)];

        newButton.addEventListener('click', () => {
            filterButtons.forEach((btn) => btn.classList.remove('is-active'));
            newButton.classList.add('is-active');
            dropdownContainer.classList.remove('is-active');
            const isPertinent = newButton.getAttribute('data-pertinent') === 'true';
            recommendationsContainer.setAttribute('data-filter-selected', isPertinent ? 'pertinent' : 'bestsales');
            recommendationsContainer.classList.add('js-loading');

            const recoUrl = isPertinent
                ? recoContainer.getAttribute('data-recommendation-pertinenceurl')
                : recoContainer.getAttribute('data-recommendation-bestsalesurl');

            if (recoUrl) {
                recommendationsContainer.setAttribute('data-filter-selected', isPertinent ? 'pertinent' : 'bestsales');
                const titleMessage = recoContainer.getAttribute('data-recommendation-title');
                fetchRecommendations(recoUrl, false, blockName, titleMessage);
            }
        });
    });
};

const initRecommendationsBlock = (recommendationsContainer) => {
    const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const recommendationsFilterTriggers =
                    recommendationsContainer.querySelectorAll('.xsell-carousel__filter-btn');
                if (recommendationsFilterTriggers.length > 0) {
                    observer.disconnect();
                    recommendationsFilterTriggers.forEach((trigger) => {
                        trigger.addEventListener('click', (e) => {
                            e.stopPropagation();
                            const dropdownContainer = trigger.nextElementSibling;

                            if (dropdownContainer) {
                                openDropdown(dropdownContainer);
                                selectFilter(recommendationsContainer, dropdownContainer);
                                closeDropdown(dropdownContainer.parentNode, dropdownContainer);
                            }
                        });
                    });
                }
            }
        }
    });

    observer.observe(recommendationsContainer, { childList: true, subtree: true });
};

export default function initRecommendationsFilter() {
    const recommendationsContainers = document.querySelectorAll('[data-recommendation="potions"]');

    recommendationsContainers.forEach((recommendationsContainer) => {
        if (recommendationsContainer) {
            const cookieSortChoice = Cookies.get('dsa_sort_choice');
            const pertinentCheck = recommendationsContainer.querySelector('.xsell-carousel__filter-check.pertinence');
            const bestsalesCheck = recommendationsContainer.querySelector('.xsell-carousel__filter-check.sell');

            if (cookieSortChoice && JSON.parse(cookieSortChoice).reco_carousel === 'top_sale') {
                bestsalesCheck.classList.add('is-active');
            } else {
                pertinentCheck.classList.add('is-active');
            }
            initRecommendationsBlock(recommendationsContainer);
        }
    });
}

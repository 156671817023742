import initInteraction from './custom_pack_interaction.js';

function formatPrice(price) {
    const initPrice = price.toString();
    const int = initPrice.substr(0, initPrice.length - 2);
    const cents = initPrice.substr(initPrice.length - 2, 2);
    return Number(`${int}.${cents}`);
}

function convertPriceToCurrency(value) {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: value % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2
    }).format(value);
}

function calcPrices() {
    const customPackItems = document.querySelectorAll('.custom-pack__item');
    const totalPrices = {
        price: 0,
        stripedPrice: 0,
        discount: 0
    };

    customPackItems.forEach((item) => {
        const price = item.querySelector('.custom-pack__main-product').getAttribute('data-price');
        const stripedPrice = item.querySelector('.custom-pack__main-product').getAttribute('data-striped-price');
        totalPrices.price += formatPrice(price);
        totalPrices.stripedPrice += formatPrice(stripedPrice > 0 ? stripedPrice : price);
        totalPrices.discount += formatPrice(stripedPrice > 0 ? stripedPrice - price : 0);
    });

    return totalPrices;
}

function updatePrice() {
    const prices = calcPrices();
    const priceElement = document.querySelector('.recommendation-summary__price');
    const stripedPriceElement = document.querySelector('.js-striped-price');
    const discountElement = document.querySelector('.recommendation-summary__discount-price');
    priceElement.textContent = convertPriceToCurrency(prices.price);

    if (prices.discount > 0) {
        document.querySelector('.recommendation-summary__total-discount').style.display = 'flex';
        priceElement.classList.add('recommendation-summary__price--is-striped');
        stripedPriceElement.textContent = convertPriceToCurrency(prices.stripedPrice);
        discountElement.textContent = convertPriceToCurrency(prices.discount);
    } else {
        document.querySelector('.recommendation-summary__total-discount').style.display = 'none';
        priceElement.classList.remove('recommendation-summary__price--is-striped');
    }
}

function updateCustomPackBuyBox(nbProducts) {
    document.querySelector('.recommendation-summary__products-total').innerHTML = `${nbProducts} produits`;
    document.getElementById('js-custom-pack__btn-buy').disabled = nbProducts <= 1;
    document.querySelector('.recommendation-summary__products').style.display = nbProducts > 1 ? 'block' : 'none';
    document.querySelector('.recommendation-summary__price-container').style.display =
        nbProducts > 1 ? 'block' : 'none';
}

function updateProducts() {
    let nbProducts = 1;
    const customPackItems = document.querySelectorAll('.js-custom-pack .custom-pack__item');

    customPackItems.forEach((item) => {
        const isChecked = item.querySelector('.js-checkbox-input').checked;
        const priceObj = item.querySelector('.custom-pack__main-product');
        if (isChecked) nbProducts += 1;
        priceObj.setAttribute('data-price', isChecked ? priceObj.getAttribute('data-selected-price') : 0);
        priceObj.setAttribute(
            'data-striped-price',
            isChecked ? priceObj.getAttribute('data-selected-striped-price') : 0
        );
    });

    document.querySelectorAll('.recommendation-select__item-button').forEach((button) => {
        button.addEventListener('click', initInteraction);
    });

    updateCustomPackBuyBox(nbProducts);
}

function checkboxTracking(checkbox) {
    const checkboxTrackingText = 'product_earlybirds_recoauto-pack';
    const eventId = checkboxTrackingText + (checkbox.checked ? '_coche' : '_decoche');
    if (window.DARTY_CONF.OMNITURE) {
        window.DARTY_CONF.OMNITURE.triggerTracking(eventId, 'clic');
    }
}

function reload() {
    updateProducts();
    updatePrice();
}

function updateCheckBox(event) {
    checkboxTracking(event.target);
    reload();
}

export default function initPrices() {
    reload();
    document.querySelectorAll('.js-checkbox-input').forEach((checkbox) => {
        checkbox.addEventListener('change', updateCheckBox);
    });
    document.addEventListener('custom_pack.item_selected', reload);
}

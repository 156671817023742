import initCustomPack from './custom_pack/custom_pack_init.js';
import { isWiremockEnabled, getPertinenceUrl, getBestSalesUrl } from './wiremock-config.js';

export const initSplideReco = (container) => {
    const itemsPerPage = container.getAttribute('data-items-per-page')
        ? container.getAttribute('data-items-per-page')
        : 5;
    const svgPath =
        'M11.0931174,5.93875 C10.021207,6.93160701 9.95712157,8.60543171 10.9499786,9.67734212 C10.9951961,9.72615993 11.0422393,9.77325437 11.091008,9.81852493 L22.5978408,20.5 L22.5978408,20.5 L11.091008,31.1814751 C10.0201778,32.1754971 9.95791257,33.8493905 10.9519346,34.9202206 C10.9972052,34.9689892 11.0442996,35.0160325 11.0931174,35.06125 C12.2741371,36.1551695 14.0983325,36.1551695 15.2793522,35.06125 L31,20.5 L31,20.5 L15.2793522,5.93875 C14.0983325,4.84483051 12.2741371,4.84483051 11.0931174,5.93875 Z';
    const splideOptions = {
        drag: true,
        pagination: false,
        snap: true,
        perPage: itemsPerPage,
        gap: 10,
        type: 'loop',
        lazyLoad: true,
        arrowPath: svgPath,
        arrows: typeof DARTY_CONF.CONST.MOBILE_URL === 'undefined',
        fixedWidth: typeof DARTY_CONF.CONST.MOBILE_URL !== 'undefined' ? '230px' : '',
        i18n: {
            prev: 'Slide précédente',
            next: 'Slide suivante',
            carousel: 'Slider',
            slideLabel: 'Slide %s'
        }
    };
    const xsellCarousel = container.querySelector('[data-recommendation="potions"] .splide');
    const splideList = container.querySelector('.splide__list');

    if (splideList) {
        const splideItem = splideList.querySelectorAll('.xsell-carousel__splide');
        let splide;

        if (splideItem.length >= 1) {
            splide = new Splide(xsellCarousel, splideOptions);
            splide.mount();
        }
    }
};

export const initSidePanel = () => {
    $(document).on('ajax_reco_loaded.earlybirds', (event, container) => {
        if (!$('body').data('mobile')) {
            if (window.DARTY_CONF && typeof window.DARTY_CONF.bindTriggerDeliveryDispo === 'function') {
                window.DARTY_CONF.bindTriggerDeliveryDispo();
            }

            // pour init les boutons d'ajout panier
            window.DARTY_CONF.COMMON_WAREHOUSE.init(container);
        }
    });
};

export const loadAjaxScript = () => {
    if (document.querySelector('body').getAttribute('data-is-mobile') !== 'true') {
        if (
            typeof window.DARTY_CONF.bindTriggerDeliveryDispo !== 'undefined' &&
            typeof window.DARTY_CONF.bindTriggerDeliveryDispo === 'function'
        ) {
            window.DARTY_CONF.bindTriggerDeliveryDispo();
        }

        if (
            typeof window.DARTY_CONF.COMMON_WAREHOUSE !== 'undefined' &&
            typeof window.DARTY_CONF.COMMON_WAREHOUSE.init === 'function'
        ) {
            window.DARTY_CONF.COMMON_WAREHOUSE.init();
        }
    }
};

export const fetchRecommendations = (ajaxUrl, isPack, blockName, titleMessage) => {
    let ajaxParams = {};
    if (isWiremockEnabled()) {
        ajaxParams = {
            method: 'GET'
        };
    } else {
        const recoToken = document
            .querySelector('.recommendation-container')
            .parentElement.getAttribute('data-recommendation-token');

        ajaxParams = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${recoToken}`
            }
        };
    }

    if (typeof blockName !== 'undefined') {
        document.querySelector(`.recommendation-container__${blockName}`).classList.add('js-loading');
    }

    fetch(ajaxUrl, ajaxParams, blockName)
        .then((response) => response.json())
        .then((data) => {
            const ajaxParamsPotions = {
                items: JSON.stringify(data.items)
            };
            if (!isPack) {
                ajaxParamsPotions.titleMessage = titleMessage;
            }
            const familyIdElement = document.querySelector('[data-recommendation-family-id]');
            const familyId = familyIdElement
                ? familyIdElement.getAttribute('data-recommendation-family-id').replace(/\s+/g, '')
                : null;
            const recommendationUrl = `/nav/extra/ajax/recommendations${isPack ? `/configurablepack?familyId=${familyId}` : ''}`;
            fetch(recommendationUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: new URLSearchParams(ajaxParamsPotions)
            }).then((dataReceived) => {
                dataReceived.text().then((html) => {
                    if (!isPack) {
                        const recoContainer = document.querySelector(`.recommendation-container__${blockName}`);
                        recoContainer.classList.remove('js-loading');
                        recoContainer.innerHTML = html;
                        initSplideReco(document.querySelector(`[data-recommendation-block="${blockName}"]`));
                        loadAjaxScript();

                        if (blockName === 'layer_push') {
                            const eventName = 'ajax_reco_loaded.layerpush';
                            const eventData = [];
                            $(document).trigger(eventName, eventData);
                            initSidePanel();
                        }
                    } else {
                        document.querySelector('.recommendation-container').innerHTML = html;
                        initCustomPack();
                    }
                });
            });
        })
        .catch(() => {
            document.querySelectorAll('.custom-pack__title, .custom-pack__container').forEach((element) => {
                element.remove();
            });
        });
};

export function initRecommendationsPacks() {
    let ajaxUrl;
    document.querySelectorAll('[data-recommendation="potions-pack"]').forEach((element) => {
        ajaxUrl = element.getAttribute('data-recommendation-url');
        if (ajaxUrl !== '') {
            fetchRecommendations(ajaxUrl, true);
        } else {
            document.querySelectorAll('.custom-pack__title, .custom-pack__container').forEach((item) => {
                item.remove();
            });
        }
    });
}

export function initRecommendations() {
    document.querySelectorAll('[data-recommendation="potions"]').forEach((element) => {
        const cookieSortChoice = Cookies.get('dsa_sort_choice');
        const blockName = element.getAttribute('data-recommendation-block');
        let ajaxUrl;

        if (cookieSortChoice && JSON.parse(cookieSortChoice).reco_carousel === 'top_sale') {
            ajaxUrl = getBestSalesUrl() || element.getAttribute('data-recommendation-bestsalesurl');
        } else {
            ajaxUrl = getPertinenceUrl() || element.getAttribute('data-recommendation-pertinenceurl');
        }

        fetchRecommendations(ajaxUrl, false, blockName);
    });
}
